import { useRouter } from "next/router"
import { useMutation } from "@blitzjs/rpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { Modal } from "antd"
import toast from "@Components/ui/Toast"
import { CustomerStatus } from "db"
import { useCallback, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { LoginSchema, LoginSchemaType } from "app/auth/validations"
import { showErrorModal } from "@Utils/errors"
import AuthFormCard from "./AuthFormCard"
import login from "app/auth/mutations/login"
import useEffectOnce from "@Hooks/useEffectOnce"
import Link from "@Components/ui/Link"
import Button from "@Components/ui/Button"
import { FormGroup, FormItem, FormLabel, FormMessage } from "@Components/ui/Form"
import Input from "@Components/ui/Input"
import { useSocket } from "app/sockets/SocketContext"

interface LoginFormProps {
  onSuccess?: () => void
}

const defaultValues: LoginSchemaType = {
  email: "",
  password: "",
  socketId: "",
}

export const LoginForm: React.FC<LoginFormProps> = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loginMutation] = useMutation(login)
  const router = useRouter()
  const queryParams = useRouter().query

  const form = useForm<LoginSchemaType>({
    resolver: zodResolver(LoginSchema),
    mode: "onChange",
    defaultValues,
  })

  const {
    register,
    formState: { errors, isValid },
    getValues,
    handleSubmit,
  } = form

  const email = form.watch("email")

  const socket = useSocket()

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    const values = getValues()
    try {
      const user = await loginMutation({ ...values, socketId: socket?.id || "" })

      if (!user.emailConfirmed) {
        await router.push(`/email-confirmation/${user.email}`)
        return
      }

      onSuccess?.()
      await router.push(
        user?.customer?.status === CustomerStatus.ONBOARDING ? "/onboarding" : "/main"
      )

      if (!window.frameElement) {
        toast.success("Logged in successfully")
      }
    } catch (error: any) {
      console.log("Log in error", error)
      showErrorModal(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [getValues, loginMutation, onSuccess, router, socket?.id])

  const forgotPasswordLink = email ? `/forgot-password?email=${email}` : "/forgot-password"

  useEffectOnce(() => {
    if ("account-deletion" in queryParams) {
      Modal.info({
        title: "Your BrokerBlocks account is scheduled for deletion",
        content: (
          <div>
            <p>
              You have been logged out and your account has been scheduled for deletion in 10 days.
            </p>
            <p>Deleting of the account after 10 days is irreversible.</p>
            <p>
              If you log in during those 10 days, then the scheduled deletion will get cancelled and
              you will get an e-mail about this fact.
            </p>
          </div>
        ),
        okButtonProps: {
          className: "bg-blue-600",
        },
        onOk: () => {
          router.push("/login")
        },
      })
    }
  })

  return (
    <FormProvider {...form}>
      <AuthFormCard
        containerClassName="md:max-w-[48rem]"
        title="Welcome"
        // description={
        //   <Link data-testid="signup-link" href="/signup">
        //     {"Don't have an account? Sign Up"}
        //   </Link>
        // }
        description=""
        ssoButtons
      >
        <div className="my-[2.4rem] md:my-[4rem]">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <FormGroup>
              <FormItem>
                <FormLabel htmlFor="input-email">Email</FormLabel>
                <Input
                  {...register("email")}
                  id="input-email"
                  data-testid="signin-email-input"
                  placeholder="janedoe@example.com"
                />
                {errors.email && <FormMessage error>{errors.email.message}</FormMessage>}
              </FormItem>
              <FormItem>
                <FormLabel htmlFor="input-password">Password</FormLabel>
                <Input
                  {...register("password")}
                  id="input-password"
                  data-testid="signin-password-input"
                  placeholder="****"
                  type="password"
                  togglePassword
                />
                {errors.password && <FormMessage error>{errors.password.message}</FormMessage>}
              </FormItem>
              <FormItem className="justify-center items-center mt-[0.8rem]">
                <Button
                  version="new"
                  className="w-fit"
                  data-testid="signin-button"
                  type="submit"
                  disabled={!isValid}
                  loading={isLoading}
                >
                  Log In
                </Button>
              </FormItem>
            </FormGroup>
          </form>
        </div>
        <div className="text-center">
          <Link data-testid="forgot-password-link" href={forgotPasswordLink}>
            Forgot password?
          </Link>
        </div>
      </AuthFormCard>
    </FormProvider>
  )
}

export default LoginForm
