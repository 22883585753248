import { cn } from "@Utils/theme"

export interface FormRowProps extends React.HTMLAttributes<HTMLDivElement> {}

const FormRow = ({ className, children, ...props }: FormRowProps) => {
  return (
    <div
      className={cn("flex flex-col gap-[1.6rem] md:flex-row md:gap-[2rem]", className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default FormRow
