import { isValidPhoneNumber } from "react-phone-number-input"
import { z } from "zod"

export const hasLowercaseLetterRegexp = /[a-z]/
export const hasUppercaseLetterRegexp = /[A-Z]/
export const hasUpperAndLowercaseLetterRegexp = /(?=.*[a-z])(?=.*[A-Z])/
export const hasNumberRegexp = /[0-9]/
export const hasSpecialCharacterRegexp = /[\^$*.\[\]{}()?\-"!@#%&\/\\,><':;|_~`+=]/

export const email = z
  .string()
  .email()
  .transform((str) => str.toLowerCase().trim())

export const contactEmail = z
  .string()
  .email()
  .transform((str) => str.trim())
  .optional()

export const password = z
  .string()
  .min(10, { message: "Password must be at least 10 characters long" })
  .regex(hasLowercaseLetterRegexp, {
    message: "Password must contain at least one lowercase letter",
  })
  .regex(hasUppercaseLetterRegexp, {
    message: "Password must contain at least one uppercase letter",
  })
  .regex(hasNumberRegexp, {
    message: "Password must contain at least one number",
  })
  .regex(hasSpecialCharacterRegexp, {
    message: "Password must contain at least one special character",
  })
  .transform((str) => str.trim())

export const SignupSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  mobileNumber: z
    .string()
    .refine((value) => isValidPhoneNumber(value), { message: "Invalid phone number" }),
  email,
  contactEmail,
  personalMobileNumber: z
    .string()
    .refine((value) => !value || isValidPhoneNumber(value), { message: "Invalid phone number" })
    .optional(),
  password,
  token: z.string(),
  nid: z.string().optional(),
  disableRecaptchaKey: z.string().optional(),
  socketId: z.string(),
})

export type SignupSchemaType = z.infer<typeof SignupSchema>

export const LoginSchema = z.object({
  email,
  password,
  socketId: z.string(),
})

export type LoginSchemaType = z.infer<typeof LoginSchema>

export const ForgotPasswordSchema = z.object({
  email,
})

export type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>

const ResetPasswordSchemaBase = z.object({
  password: password,
  passwordConfirmation: password,
  token: z.string(),
})

export const ResetPasswordSchema = ResetPasswordSchemaBase.refine(
  (data) => data.password === data.passwordConfirmation,
  { message: "Passwords do not match" }
)

export type ResetPasswordSchemaType = z.infer<typeof ResetPasswordSchema>

export const ResetPasswordFormSchema = ResetPasswordSchemaBase.merge(
  z.object({ passwordConfirmation: z.string() })
).refine((data) => data.password === data.passwordConfirmation, {
  message: "Passwords do not match",
  path: ["passwordConfirmation"],
})

export type ResetPasswordFormSchemaType = z.infer<typeof ResetPasswordFormSchema>

export const ResendConfirmationEmailSchema = z.object({
  email,
  socketId: z.string(),
})

export const ChangePasswordSchemaBase = z.object({
  currentPassword: password,
  password: password,
  passwordConfirmation: password,
})

export const ChangePasswordSchema = ChangePasswordSchemaBase.refine(
  (data) => data.password === data.passwordConfirmation,
  { message: "Passwords do not match" }
)

export const CreateLoginAndRedirectTokenSchema = z.object({
  redirectURL: z.string(),
})

export const RegisterSocketIdSchema = z.object({
  socketId: z.string(),
})
